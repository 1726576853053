import React from "react";
import "../../styling/templates.css";

const Card = (props) => {
  return (
    <div className="card" style={{ backgroundColor: "#157cab" }}>
      <div className="card-content">
        <h2 className="download-link-topic">{props.topic}</h2>
        <div className="download-links">
          <a href={props.link1} className="download-link" download>
            {props.link1Topic}
          </a>
          <a href={props.link2} className="download-link" download>
            {props.link2Topic}
          </a>
          <a href={props.link3} className="download-link" download>
            {props.link3Topic}
          </a>
          <a href={props.link4} className="download-link" download>
            {props.link4Topic}
          </a>
        </div>
      </div>
    </div>
  );
};

const Page1 = () => {
  const cards = [
    {
      topic: "Interim financial statements 2024/25",
      link1Topic: "Jun-24 | Download",
      link1: "/pdf/upload_report_file/30.06.2024.pdf",
    },
    {
      topic: "Interim financial statements 2023/24",
      link1Topic: "Mar-24 | Download",
      link1: "/pdf/upload_report_file/31.03.2024.pdf",
      link2Topic: "Dec-23 | Download",
      link2: "/pdf/upload_report_file/31.12.2023.pdf",
      link3Topic: "Sep-23 | Download",
      link3:
        "/pdf/upload_report_file/Interim%20Financial%20Statements%20as%20of%2030-09-2023.pdf",
      link4Topic: "Jun-23 | Download",
      link4: "/pdf/upload_report_file/30.%2006.%202023.pdf",
    },
    {
      topic: "Interim financial statements 2022/23",
      link1Topic: "Mar-23 | Download",
      link1: "/pdf/upload_report_file/31.%2003.%202023.pdf",
      link2Topic: "Dec-22 | Download",
      link2: "/pdf/upload_report_file/31.%2012.%202022.pdf",
      link3Topic: "Sep-22 | Download",
      link3: "/pdf/upload_report_file/1524_1667553930801.pdf",
      link4Topic: "Jun-22 | Download",
      link4: "/pdf/upload_report_file/1524_1658480790060.06.2022.pdf",
    },
    {
      topic: "Interim financial statements 2021/22",
      link1Topic: "Mar-22 | Download",
      link1: "/pdf/upload_report_file/1524_1653988348330.pdf",
      link2Topic: "Dec-21 | Download",
      link2: "/pdf/upload_report_file/1524_1644551511347.pdf",
      link3Topic: "Sep-21 | Download",
      link3: "/pdf/upload_report_file/1524_1635740084284.pdf",
      link4Topic: "Jun-21 | Download",
      link4: "/pdf/upload_report_file/1524_1627615824757.pdf",
    },
    {
      topic: "Interim financial statements 2020/21",
      link1Topic: "Mar-21 | Download",
      link1: "/pdf/upload_report_file/1524_1622432752115.pdf",
      link2Topic: "Dec-20 | Download",
      link2: "/pdf/upload_report_file/1524_1611892839571.pdf",
      link3Topic: "Sep-20 | Download",
      link3: "/pdf/upload_report_file/1524_1603427932276.pdf",
      link4Topic: "Jun-20 | Download",
      link4: "/pdf/upload_report_file/1524_1595585444840.pdf",
    },
  ];

  return (
    <div>
      <div className="download-card-grid">
        {cards.map((card, index) => (
          <Card
            key={index}
            topic={card.topic}
            link1={card.link1}
            link2={card.link2}
            link3={card.link3}
            link4={card.link4}
            link1Topic={card.link1Topic}
            link2Topic={card.link2Topic}
            link3Topic={card.link3Topic}
            link4Topic={card.link4Topic}
          />
        ))}
      </div>
    </div>
  );
};

export default Page1;
