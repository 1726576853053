import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "../../styling/templates.css";
import Heder from "../../components/navigation-header";
import Footer from "../../components/footer";
import DownloadCardGrid from "./card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDown } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "gatsby";
import MainBanner from "../../images/IRBanner.jpeg";
import MainBanner2 from "../../images/IRBanner2.jpeg";
import CIpart from "./ci-part";

export default function InvestorRelations() {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <div>
      <Heder />

      <center>
        <div className="section-background">
          <h2 className="investor-relations-topic">
            Investor relations <br />
            Be a part of our journey
          </h2>
        </div>
      </center>

      <div className="pc">
        <div
          style={{
            backgroundImage: `url(${MainBanner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100vh",
            marginBottom: "100px",
          }}
        >
          <div className="overlayStyle6"></div>
        </div>
      </div>
      <div className="mobile">
        <div
          style={{
            backgroundImage: `url(${MainBanner2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "700px",
            marginBottom: "100px",
          }}
        >
          <div className="overlayStyle8"></div>
        </div>
      </div>

      <center>
        <div className="section-background">
          <center>
            <p className="investor-relations-para-italic">
              We aspire to create value for all stakeholders through sustainable
              means. We monitor market developments and evaluate strategic
              opportunities regularly, in order to enhance shareholder value. By
              maintaining consistent performance and following a disciplined
              investment strategy, we open up more growth opportunities and
              achieve sustainable returns.
            </p>

            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <div className="pc">
                <div style={{ marginTop: "60px" }}>
                  <table style={{ width: "100%", textAlign: "center" }}>
                    <tr>
                      <td style={{ width: "200px", verticalAlign: "top" }}>
                        <p className="number-loader">
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={5.4}
                              decimals={1}
                              duration={2}
                              delay={0}
                            />
                          )}{" "}
                          Bn
                        </p>
                        <p className="Num-text">REVENUE FOR FY 2024</p>
                      </td>
                      <td className="vertical-line"></td>
                      <td style={{ width: "200px", verticalAlign: "top" }}>
                        {/* <p className="number-loader">
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={29}
                              duration={2}
                              delay={0}
                            />
                          )}{" "}
                          %
                        </p>
                        <p className="Num-text">REVENUE GROWTH</p> */}
                        <p className="number-loader">
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={1.1}
                              decimals={1}
                              duration={2}
                              delay={0}
                            />
                          )}{" "}
                          Bn
                        </p>
                        <p className="Num-text">GROSS PROFITS</p>
                      </td>
                      <td className="vertical-line"></td>
                      <td style={{ width: "200px", verticalAlign: "top" }}>
                        <p className="number-loader">
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={591}
                              duration={2}
                              delay={0}
                            />
                          )}{" "}
                          Mn
                        </p>
                        <p className="Num-text">CASH PROFITS</p>
                      </td>
                    </tr>
                  </table>
                </div>
                <div style={{ marginTop: "50px" }}></div>
                <div>
                  <table style={{ width: "60%", textAlign: "center" }}>
                    <tr>
                      {/* <td style={{ width: "200px", verticalAlign: "top" }}>
                        <p className="number-loader">
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={511}
                              duration={2}
                              delay={0}
                            />
                          )}{" "}
                          Mn
                        </p>
                        <p className="Num-text">PAT decreased by 21%</p>
                      </td>
                      <td className="vertical-line"></td> */}
                      <td style={{ width: "200px", verticalAlign: "top" }}>
                        <p className="number-loader">
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={7.8}
                              decimals={1}
                              duration={2}
                              delay={0}
                            />
                          )}{" "}
                          Bn
                        </p>
                        <p className="Num-text">TOTAL ASSETS</p>
                      </td>
                    </tr>
                  </table>
                  <div style={{ marginTop: "40px", marginBottom: "70px" }}>
                    <a
                      href="/pdf/2024/Full%20Financial%20Results.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="i-r-button" id="button">
                        VIEW FULL FINANCIAL RESULTS
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              {/* ll */}
              <div className="mobile">
                {/* 01 */}
                <p className="number-loader">
                  {counterOn && (
                    <CountUp
                      start={0}
                      end={5.4}
                      decimals={1}
                      duration={2}
                      delay={0}
                    />
                  )}{" "}
                  Bn
                </p>
                <p className="Num-text">REVENUE FOR FY 2024</p>
                {/* 02 */}
                {/* <p className="number-loader">
                  {counterOn && (
                    <CountUp start={0} end={29} duration={2} delay={0} />
                  )}{" "}
                  %
                </p>
                <p>REVENUE GROWTH</p> */}
                {/* 03 */}
                <p className="number-loader">
                  {counterOn && (
                    <CountUp
                      start={0}
                      end={1.1}
                      decimals={1}
                      duration={2}
                      delay={0}
                    />
                  )}{" "}
                  Bn
                </p>
                <p className="Num-text">GROSS PROFITS</p>
                {/* 04 */}
                <p className="number-loader">
                  {counterOn && (
                    <CountUp start={0} end={591} duration={2} delay={0} />
                  )}{" "}
                  Mn
                </p>
                <p className="Num-text">CASH PROFITS</p>
                {/* 05 */}
                <p className="number-loader">
                  {counterOn && (
                    <CountUp
                      start={0}
                      end={7.8}
                      decimals={1}
                      duration={2}
                      delay={0}
                    />
                  )}{" "}
                  Bn
                </p>
                <p className="Num-text">TOTAL ASSETS</p>
                <div style={{ marginTop: "40px", marginBottom: "70px" }}>
                  <a
                    href="/pdf/2024/Full%20Financial%20Results.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="i-r-button" id="button">
                      VIEW FULL FINANCIAL RESULTS
                    </button>
                  </a>
                </div>
              </div>
            </ScrollTrigger>
          </center>
        </div>
      </center>

      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/images/INVESTOR_RELATIONS/annual-report1.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <center>
          <div className="section-background">
            <br />
            <div className="pc">
              <h2 className="download-box-topic">
                The Annual Reports are available <br />
                by clicking on the links below.
              </h2>
            </div>

            <div className="mobile">
              <h2 className="download-box-topic">
                The Annual Reports are available by clicking on the links below.
              </h2>
            </div>

            <div className="download-box">
              <br />
              <div style={{ marginLeft: "40px" }}>
                <h2 className="bullect-list-topic">
                  Download &nbsp; <FontAwesomeIcon icon={faCircleDown} />
                </h2>

                <div className="pc">
                  <div className="bullet-list">
                    <div style={{ marginBottom: "-10px" }}>
                      <ul>
                        <a
                          href="/pdf/2024/BPPL_HOLDINGS PLC_AR_2023-24.pdf"
                          className="bullect-gap"
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2023/24
                          </div>
                        </a>
                        <a
                          href="/pdf/2023/BPPL_HOLDINGS_20PLC_AR_2022-23.pdf"
                          className="bullect-gap"
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2022/23
                          </div>
                        </a>
                        <a
                          href="/pdf/2022/BPPL_HOLDINGS%20PLC_AR_2021-22.pdf"
                          className="bullect-gap"
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2021/22
                          </div>
                        </a>
                        <a
                          href="/pdf/2021/Annual%20Report%202020%2021.pdf"
                          className="bullect-gap"
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2020/21
                          </div>
                        </a>
                        <a
                          href="/pdf/2020/BPPL_Holdings_PLC_AR_2019_20.pdf"
                          className="bullect-gap"
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2019/20
                          </div>
                        </a>
                        <a
                          href="/pdf/2019/BPPL_Holdings_PLC_AR_2018_19.pdf"
                          className="bullect-gap"
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2018/19
                          </div>
                        </a>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mobile">
                  <div className="bullet-list">
                    <div style={{ marginBottom: "-10px" }}>
                      <ul>
                        <a
                          href="/pdf/2024/BPPL_HOLDINGS PLC_AR_2023-24.pdf"
                          className="bullect-gap"
                          download
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2023/24
                          </div>
                        </a>
                        <a
                          href="/pdf/2023/BPPL_HOLDINGS_20PLC_AR_2022-23.pdf"
                          className="bullect-gap"
                          download
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2022/23
                          </div>
                        </a>
                        <a
                          href="/pdf/2022/BPPL_HOLDINGS%20PLC_AR_2021-22.pdf"
                          className="bullect-gap"
                          download
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2021/22
                          </div>
                        </a>
                        <a
                          href="/pdf/2021/Annual%20Report%202020%2021.pdf"
                          className="bullect-gap"
                          download
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2020/21
                          </div>
                        </a>
                        <a
                          href="/pdf/2020/BPPL_Holdings_PLC_AR_2019_20.pdf"
                          className="bullect-gap"
                          download
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2019/20
                          </div>
                        </a>
                        <a
                          href="/pdf/2019/BPPL_Holdings_PLC_AR_2018_19.pdf"
                          className="bullect-gap"
                          download
                        >
                          <li />
                          <div className="bullet-list-text">
                            Annual Report 2018/19
                          </div>
                        </a>
                      </ul>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className="mobile">
              <br />
            </div>
          </div>
        </center>
      </div>

      {/* 01 */}
      <div style={{ marginTop: "120px" }}>
        <center>
          <div className="section-background">
            <div className="two-column-container">
              <div className="left-column">
                {/* Content for the left column */}
                <div style={{ backgroundColor: "#1b1464" }}>
                  <center>
                    <h2 className="topic-invester">Corporate governance</h2>
                  </center>
                </div>
                <img
                  src="/images/INVESTOR_RELATIONS/corporate-govenance.jpg"
                  loading="lazy"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "60px",
                  }}
                  alt="cooparate-governance"
                />
              </div>
              <div className="right-column">
                {/* Content for the right column */}
                <div style={{ marginLeft: "33px", marginRight: "20px" }}>
                  <div className="invester-bullet-bold">
                    <ul>
                      <li>Audit committee</li>
                      <div className="invester-bullet-normal">
                        <ul>
                          <li>
                            Chairman – Mr Manjula Hiranya De Silva – Independent
                            Director
                          </li>
                          <li>
                            Member – Mrs Sharmini Tamara Ratwatte – Independent
                            Director
                          </li>
                          <li>
                            Member – Mr Ranil Prasad Pathirana – Non executive
                            Director
                          </li>
                          <li>
                            <a href="/pdf/2023/Audit%20Committee%20Charter.pdf">
                              Audit Committee Charter Download
                            </a>
                          </li>
                        </ul>
                      </div>
                      <li>Related Party transaction committee</li>
                      <div className="invester-bullet-normal">
                        <ul>
                          <li>
                            Chairman – Mr Manjula Hiranya De Silva – Independent
                            Director
                          </li>
                          <li>
                            Member – Mrs Sharmini Tamara Ratwatte – Independent
                            Director
                          </li>
                          <li>
                            Member – Mr Ranil Prasad Pathirana – Non executive
                            Director
                          </li>
                        </ul>
                      </div>
                      <li>Remuneration committee</li>
                      <div className="invester-bullet-normal">
                        <ul>
                          <li>Chairman – Mr Savantha De Saram- Indepandent</li>
                          <li>
                            Member – Mrs Sharmini Tamara Ratwatte – Independent
                            Director
                          </li>
                          <li>
                            Member – Mr Ranil Prasad Pathirana – Non executive
                            Director
                          </li>
                          <li>
                            <a href="/pdf/downloads/RemunerationCommitteeCharter.pdf">
                              Remuneration Committee Charter Download
                            </a>
                          </li>
                        </ul>
                      </div>
                      <li>Governance Policies</li>
                      <div className="invester-bullet-normal">
                        <ul>
                          <li>
                            <a href="/pdf/Governance-Police/Grievance%20handling%20policy%20and%20procedure.pdf">
                              Grievance Handling Policy and Procedures
                            </a>
                          </li>
                          <li>
                            <a href="/pdf/Governance-Police/Prevention%20of%20harassment%20and%20bullying.pdf">
                              Prevention of Harassment and Bullying
                            </a>
                          </li>
                          <li>
                            <a href="/pdf/Governance-Police/Anti-corruption%20policy.pdf">
                              Anti-Bribery and Corruption Policy
                            </a>
                          </li>
                          <li>
                            <a href="/pdf/Governance-Police/Non%20discrimination%20and%20equal%20opppotunities.pdf">
                              Policy for Non Discrimination and Equal
                              Opportunities
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div className="horizontal-line" />
            </div>
          </div>
        </center>
      </div>

      {/* 02 */}
      <div style={{ marginTop: "50px" }}>
        <center>
          <div className="section-background">
            <div className="two-column-container">
              <div className="left-column">
                {/* Content for the left column */}
                <div style={{ backgroundColor: "#1b1464" }}>
                  <center>
                    <h2 className="topic-invester">Broker research</h2>
                  </center>
                </div>
                <img
                  src="/images/INVESTOR_RELATIONS/BROCKER.jpg"
                  loading="lazy"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "60px",
                  }}
                  alt="brocker"
                />
              </div>
              <div className="right-column">
                {/* Content for the right column */}
                <div className="invester-bullet-normal">
                  <p style={{ marginLeft: "20px", marginRight: "20px" }}>
                    Broker research covering BPPL Holdings is produced by the
                    following institutions.
                  </p>
                  <ul>
                    <div style={{ marginLeft: "33px", marginRight: "20px" }}>
                      <li>NDB Securities (Pvt) Ltd.</li>
                      <div className="invester-bullet-normal">
                        <ul>
                          <li>
                            <a href="/pdf/broker_research/BPPL%20SL%20-%20Initiation%20Report%20-%20From%20Waste%20to%20Wardrobe%20-%20BUY.pdf">
                              BPPL Holdings PLC Investment Thesis
                            </a>
                          </li>
                        </ul>
                      </div>
                      <li>Capital Alliance Securities (Pvt) Ltd.</li>
                      <div className="invester-bullet-normal">
                        <ul>
                          <li>
                            <a href="/pdf/broker_research/BPPL_Company%20Report_BUY_07July21.pdf">
                              BPPL Holdings PLC Investment Thesis
                            </a>
                          </li>
                        </ul>
                      </div>
                      <li>Lanka Securities (Pvt) Ltd.</li>
                      <div className="invester-bullet-normal">
                        <ul>
                          <li>
                            <a href="/pdf/broker_research/BPPL%20SL%20Equity_ER1QFY21.pdf">
                              BPPL Holdings PLC 1Q21 Results Summary
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "3px" }}>
              <div className="horizontal-line" />
            </div>
          </div>
        </center>
      </div>

      {/* 03 */}
      <div style={{ marginTop: "50px" }}>
        <center>
          <div className="section-background">
            <div className="two-column-container">
              <div className="left-column">
                {/* Content for the left column */}
                <div style={{ backgroundColor: "#1b1464" }}>
                  <center>
                    <h2 className="topic-invester">AGM updates</h2>
                  </center>
                </div>
                <img
                  src="/images/INVESTOR_RELATIONS/AGM-UPDATEs.jpg"
                  loading="lazy"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "60px",
                  }}
                  alt="agm-updates"
                />
              </div>
              <div className="right-column">
                {/* Content for the right column */}
                <div className="invester-bullet-normal">
                  <p style={{ marginLeft: "20px", marginRight: "20px" }}>
                    Notice of AGM and related documents
                  </p>
                  <ul>
                    <div style={{ marginLeft: "33px", marginRight: "20px" }}>
                      <li>
                        <a href="/pdf/2024/AGM.pdf">Notice to Shareholders</a>
                      </li>
                    </div>
                  </ul>
                  <br />
                  <p style={{ marginLeft: "20px", marginRight: "20px" }}>
                    Notice of EGM and related documents
                  </p>
                  <ul>
                    <div style={{ marginLeft: "33px", marginRight: "20px" }}>
                      <li>
                        <a href="/pdf/2024/EGM.pdf">Notice to Shareholders</a>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "3px" }}>
              <div className="horizontal-line" />
            </div>
          </div>
        </center>
      </div>

      {/* 04 */}
      <div style={{ marginTop: "50px" }}>
        <center>
          <div className="section-background">
            <div className="two-column-container">
              <div className="left-column">
                {/* Content for the left column */}
                <div style={{ backgroundColor: "#1b1464" }}>
                  <center>
                    <h2 className="topic-invester">Corporate Calendar</h2>
                  </center>
                </div>
                <img
                  src="/images/INVESTOR_RELATIONS/calender.jpg"
                  loading="lazy"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "60px",
                  }}
                  alt="calendar"
                />
              </div>
              <div className="right-column">
                {/* Content for the right column */}
                <div className="invester-bullet-normal">
                  <ul>
                    <div style={{ marginLeft: "33px", marginRight: "20px" }}>
                      <li>
                        2023 September – Annual General Meeting and Extrodinary
                        General Meeting
                      </li>
                      <li>
                        2023 November – Results for the half year ending 30th
                        September 2023
                      </li>
                      <li>
                        2024 February – Results for the nine months’ period
                        ending 31st December 2023
                      </li>
                      <li>
                        2024 May – Results for the financial year ending 31st
                        March 2024
                      </li>
                      <li>
                        2024 August – Annual Report for the financial year
                        ending 31st March 2024
                      </li>
                      <li>
                        2024 September – Annual General Meeting and Extrodinary
                        General Meeting
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "3px", marginBottom: "10px" }}>
              <div className="horizontal-line" />
            </div>
          </div>
        </center>
      </div>

      <br />

      <center>
        <div className="section-background">
          <div>
            <h2 className="investor-relations-sub-topic">
              Interim financial statements
            </h2>
          </div>
          <br />
          <DownloadCardGrid />
          <div style={{ marginTop: "60px" }}>
            <div className="horizontal-line" />
          </div>
        </div>
      </center>

      <center>
        <div className="section-background">
          <div style={{ marginTop: "30px" }}></div>
          <br />
          {/* /////// */}
          <CIpart />
          {/* //////// */}
          <div style={{ marginTop: "60px" }}>
            <div className="horizontal-line" />
          </div>
        </div>
      </center>

      <center>
        <div className="section-background">
          <div style={{ marginTop: "30px" }}>
            <h2 className="investor-relations-sub-topic">
              Investor relation inquiries
            </h2>
          </div>

          <div className="pc">
            <table
              style={{ width: "100%", marginTop: "30px", marginBottom: "60px" }}
            >
              <tr>
                <td style={{ width: "50%", verticalAlign: "top" }}>
                  <div style={{ marginLeft: "10px" }}>
                    {/* Left column content */}
                    <div className="investor-relations-s-topic">
                      <p>
                        Investor Relations – BPPL Holdings
                        <div className="investor-relations-s-para">
                          Level 17, Access Towers, 278/4 Union Place, Colombo
                          02, Sri Lanka. <br />
                          Tel: <a href="tel:+94112307168">
                            +94 11 2307168
                          </a> | <a href="tel:+94112307170">+94 11 2307170</a>
                          <br />
                          Email:{" "}
                          <a href="mailto:rizan@bpplholdings.com">
                            rizan@bpplholdings.com
                          </a>
                        </div>
                      </p>
                    </div>
                  </div>
                </td>

                <td style={{ width: "50%", verticalAlign: "top" }}>
                  <div>
                    {/* Right column content */}
                    <div className="investor-relations-s-topic">
                      <p>
                        Share Registrar
                        <div className="investor-relations-s-para">
                          <a
                            href="https://www.cse.lk/pages/company-profile/company-profile.component.html?symbol=BPPL.N0000"
                            target="_blank"
                          >
                            Colombo Stock Exchange
                          </a>
                        </div>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <div className="mobile">
            <div className="investor-relations-s-topic">
              <p>
                <div className="investor-relations-s-topic-p">
                  Investor Relations – BPPL Holdings
                </div>
                <div className="investor-relations-s-para">
                  Level 17, Access Towers, <br />
                  278/4 Union Place, Colombo 02, <br />
                  Sri Lanka. <br />
                  Tel: <a href="tel:+94112307168">+94 11 2307168</a> |{" "}
                  <a href="tel:+94112307170">+94 11 2307170</a>
                  <br />
                  Email:{" "}
                  <a href="mailto:rizan@bpplholdings.com">
                    rizan@bpplholdings.com
                  </a>
                </div>
              </p>
            </div>
            <div className="investor-relations-s-topic">
              <p>
                <div className="investor-relations-s-topic-p">
                  Share Registrar
                </div>
                <div className="investor-relations-s-para">
                  <a
                    href="https://www.cse.lk/pages/company-profile/company-profile.component.html?symbol=BPPL.N0000"
                    target="_blank"
                  >
                    Colombo Stock Exchange
                  </a>
                </div>
              </p>
            </div>
          </div>
        </div>
      </center>

      <div className="mobile">
        <br />
      </div>

      <Footer />
    </div>
  );
}
